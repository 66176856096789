<div class="footer fixed z-50 w-full h-16 max-w-lg -translate-x-1/2 bottom-4 left-1/2 mt-10">
  <div class="grid h-full max-w-lg grid-cols-5 mx-auto">
    <a routerLink="/dashboard/user-home" routerLinkActive="active"
     data-tooltip-target="tooltip-home" type="button" class="inline-flex flex-col items-center justify-center px-5 rounded-s-full group">
      <i class="fa-duotone fa-home"></i>
      <span class="sr-only">خانه</span>
  </a>
    <div id="tooltip-home" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
      خانه
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
    <a [routerLink]="['/settings','wallet']" routerLinkActive="active"
     data-tooltip-target="tooltip-wallet" type="button" class="inline-flex flex-col items-center justify-center px-5 group">
      <i class="fa-duotone fa-wallet"></i>
      <span class="sr-only">کیف پول</span>
  </a>
    <div id="tooltip-wallet" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
      کیف پول
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
    <div class="flex items-center justify-center">
      <button data-tooltip-target="tooltip-new" type="button" class="inline-flex items-center justify-center w-10 h-10 font-medium bg-blue-600 rounded-full hover:bg-blue-700 group focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
        <i class="fa-duotone fa-person-walking"></i>

        <span class="sr-only">شروع کن</span>
      </button>
    </div>
    <div id="tooltip-new" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
      شروع کن
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
    <button routerLink="/settings"
     data-tooltip-target="tooltip-settings" type="button" class="inline-flex flex-col items-center justify-center px-5 group">
      <i class="fa-duotone fa-sliders"></i>
      <span class="sr-only">تنظیمات</span>
    </button>
    <div id="tooltip-settings" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
      تنظیمات
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
    <button routerLink="/settings/profile" data-tooltip-target="tooltip-profile" type="button" class="inline-flex flex-col items-center justify-center px-5 rounded-e-full group">
      <i class="fa-duotone fa-user"></i>
      <span class="sr-only">پروفایل</span>
    </button>
    <div id="tooltip-profile" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
      پروفایل
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
  </div>
</div>

<app-public-layout>

  <div class="my-5">
    <ng-content select="[slot=hero]"></ng-content>
  </div>

  <!-- Back button in top-right corner -->
  <button (click)="goBack()" class="fixed top-6 right-16 text-2xl text-gray-500 dark:text-gray-400">
    <i class="fa-duotone fa-arrow-right"></i>
  </button>

  <div *ngIf="showProgress" class="w-full bg-gray-200 dark:bg-gray-700 rounded">
    <div class="h-2 bg-blue-500 rounded" [style.width.%]="progress"></div>
  </div>
  <div class="flex flex-1 flex-col">
    <ng-content></ng-content>
  </div>
</app-public-layout>

import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-quiz-layout',
  templateUrl: './quiz-layout.component.html',
  styleUrl: './quiz-layout.component.scss'
})
export class QuizLayoutComponent {
  @Input() step: number = 0;
  @Input('total-steps') totalSteps: number = 100;
  @Input('show-progress') showProgress: boolean = true;
  @Output() backClicked = new EventEmitter<void>();

  constructor(private location: Location) {}

  get progress() {
    return this.step / this.totalSteps * 100 > 100 ? 100 : this.step / this.totalSteps * 100;
  }

  goBack() {
    this.backClicked.emit();
    this.location.back();
  }
}
